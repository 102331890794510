// Import Core Components here
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Login } from "npmlinks-constants";
// Import App depedency here
import { Input } from "arv-reactcomponents";

class EmailInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email:  props.email,
      emailInputErrorMsg: "",
      isEmailInputValid: false,
      emailErrorClass: "",
      isValid: "",
    };

    this.onBlur = this.onBlur.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.init = this.init.bind(this);
  }

  componentDidMount(){
    this.init();
  }

  init(){
    const {email} = this.state;
    if(email){
      const e = {
        target: {
          value:email
        }
      }
      this.validateEmail(e);
    }
  }

  onBlur() {
    let emailErrorClass = "nwc-hidden";
    let isValid = "";
    if (!this.state.isEmailInputValid) {
      emailErrorClass = "";
      isValid = "is-invalid";
    }
    this.setState({ emailErrorClass, isValid });
  }

  validateEmail(e) {
    this.setState({ emailErrorClass: "nwc-hidden" });
    const validateEmailField = this.validateEmailFieldRef.getValidatedOutput(
      e.target.value,
    );
    this.setState({
      isEmailInputValid: validateEmailField.isValid,
      emailInputErrorMsg: validateEmailField.errorMsg,
      email: validateEmailField.value,
      isValid: "",
    });
    this.props.onEmailChange ? this.props.onEmailChange() : null;
  }

  static getDerivedStateFromProps(props, state) {
    const { highlightEmailField: isEmailInputValid } = props
    if (isEmailInputValid !== state.isEmailInputValid) {
      return {
        isValid: isEmailInputValid ? "is-invalid" : "",
        emailErrorClass: "",
        emailInputErrorMsg: props.userEmailInputErrorMsg
      };
    }
    return null;
  }


  render() {
    let { emailInputErrorMsg, email, isValid, emailErrorClass } = this.state;
    const { className, placeholder, name, classNameErr } = this.props;
    return (
      <div>
        <Input
          className={`${className} ${isValid}`}
          name={name}
          onBlur={this.onBlur}
          onChange={this.validateEmail}
          placeholder={placeholder}
          type="email"
          value={email}
          ref={values => {
            this.validateEmailFieldRef = values;
          }}
          validateWithPattern={[
            {
              pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              msg: Login.REGISTRATION_MSG.INVALID_EMAIL_MSG,
            },
          ]}
        />
         <div className={` ${classNameErr} ${emailErrorClass}`}>
          {emailInputErrorMsg}
        </div>
      </div>
    );
  }
}

EmailInput.defaultProps = {
  className: "",
  required: false,
  userEmailInputErrorMsg: "",
  highlightEmailField: false,
  classNameErr: "nw-form-err-msg",
};

EmailInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  classNameErr: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: EmailInput,
  component: EmailInput,
};

export default ProjectComponent;
